import createEmotionCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import { hydrate } from "react-dom";
import createEmotionCacheCSS from "./server/emotions_cache";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { config } from "~/config";

const hydrateDocument = () => {
  const emotionCache = createEmotionCacheCSS();

  const accessToken = window.localStorage.getItem("token");

  let headers;
  if (accessToken) {
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    headers = {};
  }

  const client = new ApolloClient({
    uri: `${config.API_GRAPHQL_URL}`,
    cache: new InMemoryCache(),
    headers,
  });

  hydrate(
    <StrictMode>
      <CacheProvider value={emotionCache}>
        <ApolloProvider client={client}>
          <RemixBrowser />
        </ApolloProvider>
      </CacheProvider>
    </StrictMode>,
    document
  );
};

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(hydrateDocument);
} else {
  setTimeout(hydrateDocument, 1);
}
